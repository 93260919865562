<template>
  <div>
    <section id="learnmore" class="secondary w-full">
      <div class="wrapper">
        <h1 class="text-center mb-5" v-html="pageData.Title"></h1>
        <!-- <div class="m-auto mb-8 rounded-full h-20 w-20 flex items-center justify-center cursor-pointer dkgrey" @click="toggleAudio">
          <img v-show="!audioPlaying" src="@/assets/img/icons/play.svg" width="30" height="auto" class="white" alt="Play Audio Icon" />
          <img v-show="audioPlaying" src="@/assets/img/icons/pause.svg" width="30" height="auto" class="white" alt="Pause Audio Icon" />
        </div> -->
        <!-- <div class="m-auto mb-8 flex items-center"> -->
        <av-line
          id="audiocontrols"
          class="relative w-full m-auto mb-8 flex items-center"
          :line-width="1"
          :audio-src="audioFile"
        ></av-line>
        <!-- </div> -->
        <div class="content" v-html="pageData.Content"></div>
        <hr class="py-10" />
        <h1>Frequently Asked Questions</h1>
        <!-- ACCORDION MARKUP -->
        <div class="w-full lg:p-8 md:p-4 pb-20">
          <div class="w-full relative">
            <div v-for="item in faqData.accordion" :key="item.id" class="tab w-full overflow-hidden border-b">
              <input class="text-yellow absolute opacity-0 " v-bind:id="item.id" type="checkbox" name="tabs" />
              <label class="block p-5 leading-normal cursor-pointer" v-bind:for="item.id"
                ><h2>{{ item.Title }}</h2></label
              >
              <div class="tab-content overflow-hidden leading-normal">
                <p class="lg:p-4 md:p-2 text-white" v-html="item.Content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
// import SoundPlayer from '@/scripts/Sounds';
import audio from "@/assets/aud/LearnMore_normalized.mp3";

export default {
  data() {
    return {
      audioPlaying: false,
      pageData: {},
      faqData: {},
    };
  },
  created() {
    // LOAD FROM API
    axios
      .get(this.$store.state.api + "learn-more/")
      .then((response) => {
        // console.log(`learn more: ${JSON.stringify(response.data)}`);
        var showdown = require("showdown"),
          converter = new showdown.Converter(),
          html = converter.makeHtml(response.data.Content);
        this.pageData = response.data;
        this.pageData.Content = html;

        axios
          .get(this.$store.state.api + "faq/")
          .then((response) => {
            // console.log(`faq: ${JSON.stringify(response.data)}`);
            this.faqData = response.data;
            this.$forceUpdate();
          })
          .catch((error) => console.log(error));
        // this.$forceUpdate();
      })
      .catch((error) => console.log(error));

    // window.SoundPlayer = SoundPlayer;
    // SoundPlayer.init({
    //   soundList: [
    //     {
    //       id: 'LearnMore',
    //       src: audio,
    //       data: 1,
    //     },
    //   ],
    //   loop: false,
    // });
  },
  computed: {
    audioFile: function() {
      return audio;
    },
  },
  mounted() {
    // window.SoundPlayer.addEventListener('onended', this.onAudioEnd.bind(this));
    // if (!this.$store.state.aboutAudioPlayed) {
    //   window.SoundPlayer.playSound('LearnMore');
    //   this.audioPlaying = true;
    //   this.$store.dispatch('setState', { target: 'aboutAudioPlay', value: true });
    // }
  },
  methods: {
    // beforeLeave: function() {
    //   if (this.audioPlaying) {
    //     window.SoundPlayer.stopSound('LearnMore');
    //     this.audioPlaying = false;
    //   }
    // },
    // onAudioEnd: function() {
    //   this.audioPlaying = false;
    // },
    // toggleAudio: function() {
    //   if (this.audioPlaying) {
    //     window.SoundPlayer.pauseSound('LearnMore');
    //     this.audioPlaying = false;
    //   } else {
    //     window.SoundPlayer.playSound('LearnMore');
    //     this.audioPlaying = true;
    //   }
    // },
    toggle: function() {
      var myRadios = document.getElementsByName("tabs2");
      var setCheck;
      var x = 0;
      for (x = 0; x < myRadios.length; x++) {
        myRadios[x].onclick = function() {
          if (setCheck != this) {
            setCheck = this;
          } else {
            this.checked = false;
            setCheck = null;
          }
        };
      }
    },
  },
};
</script>
<style lang="scss" scope>
@import "../styles/helpers";
@import "../styles/typovars";
@import "../styles/shared";
@import "../styles/accordion";

#learnmore {
  background: $blue;
  background: rgba(51, 81, 117, .97);
  // background: rgba(63, 63, 63, 0.98);
  padding-top: 136px;
  @media only screen and (max-width: $minwidth) {
    margin-top: -116px;
    padding: 18vw 10vw 8vw;
  }
  ul {
    list-style-type: disc;
    li {
      line-height: 1.5em;
      margin-left: 30px;
      padding-left: 15px;
      color: #fff;
    }
  }
  #audiocontrols {
    div {
      width: 100%;
      margin: auto;
      &:nth-child(2) {
        display: none;
      }
      audio {
        margin: auto;
        opacity: 0.35;
      }
    }
    canvas {
      display: none;
    }
  }
  .content {
    p {
      color: #efefef;
      font-weight: 400;
      line-height: 1.5em;
    }
  }
  .tab-content {
    p {
      color: #efefef;
    }
  }
  .tab input[type="checkbox"] + label {
    &:after {
      color: $yellow;
    }
  }
}
</style>
